import Homepage from '../pages/Homepage';
import StaffPage from '../pages/StaffPage';
import DevenirLicencie from '../pages/DevenirLicencie';
import AboutUs from '../pages/AboutUs';
import Horaires from "../pages/Horaires";
import LocationMateriel from '../pages/LocationMateriel';
import EquipeDivision from '../pages/EquipeDivision';
import Calendrier from '../pages/Calendrier';
import Tournoi from '../pages/Tournoi';
import Partenariat from '../pages/Partenariat';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Maintenance from '../pages/Maintenance';
import Encheres from '../pages/Encheres';
import { PageTransition } from '../utils/PageTransition';

export const routes = [
    {
        path: "/",
        element: (
            <PageTransition>
                <Homepage />
            </PageTransition>
        )
    },
    {
        path: "/bureau-sportif",
        element: (
            <PageTransition>
                <StaffPage pageName="bureau-sportifs" pageTitle="Bureau sportif" />
            </PageTransition>
        )
    },
    {
        path: "/responsable-equipe",
        element: (
            <PageTransition>
                <StaffPage pageName="responsable-equipes" pageTitle="Responsables d'équipes" />
            </PageTransition>
        )
    },
    {
        path: "/qui-sommes-nous",
        element: (
            <PageTransition>
                <AboutUs />
            </PageTransition>
        )
    },
    {
        path: "/devenir-licencie",
        element: (
            <PageTransition>
                <DevenirLicencie />
            </PageTransition>
        )
    },
    {
        path: "/horaires",
        element: (
            <PageTransition>
                <Horaires />
            </PageTransition>
        )
    },
    {
        path: "/location-materiel",
        element: (
            <PageTransition>
                <LocationMateriel />
            </PageTransition>
        )
    },
    {
        path: "/equipe-division",
        element: (
            <PageTransition>
                <EquipeDivision />
            </PageTransition>
        )
    },
    {
        path: "/calendriers",
        element: (
            <PageTransition>
                <Calendrier />
            </PageTransition>
        )
    },
    {
        path: "/tournoi",
        element: (
            <PageTransition>
                <Tournoi />
            </PageTransition>
        )
    },
    {
        path: "/partenariat",
        element: (
            <PageTransition>
                <Partenariat />
            </PageTransition>
        )
    },
    // {
    //     path: "/vente-aux-encheres",
    //     element: (
    //         <PageTransition>
    //             <Encheres/>
    //         </PageTransition>
    //     )
    // },
    {
        path: "/protectiondesdonnees",
        element: (
            <PageTransition>
                <PrivacyPolicy />
            </PageTransition>
        )
    },
    {
        path: "/maintenance",
        element: (
            <PageTransition>
                <Maintenance />
            </PageTransition>
        )
    }
];